import React from "react";
import { Link } from "gatsby"
import Layout from "../components/layout"
import "../stylesheets/pages/casestudy.css";
import Img1 from "../../public/reimageIT1.png";
import Img2 from "../../public/reimageIT2.png";
import backArrow from "../../public/up_arrow_outline.svg"

export default function casestudy() {
  return (
    <Layout>
      <div className="casestudyWrapper mob">
        <div id="backHolder" className="mob">
          <Link to="/our-work/" className="btnC mob">
            <img src={backArrow} className="backButton mob"/>
          </Link>
        </div>
          <img id="reim" className="mob" style={{marginBottom: "10%"}} src={Img1}/>
          <img id="reim" className="mob" src={Img2}/>
      </div>
    </Layout>
  )
}
